<template>
    <img v-if="data.src" :alt="data.alt" :src="data.src" />
</template>

<script>
export default {
    props: {
        'oma': {type: Object, required: true},
        'channel': {type: Number, required: true},
        'defaut': {type: String, default: ''},
    },
    data: () => ({
        direct: {},
        replay: {},
    }),
    created: function () {
        this.direct = this.defaut
        this.replay = this.defaut
        this.oma.ows.handler(    this.channel, (data) => { this.direct = this.update(data) })
        this.oma.ows.handler('R'+this.channel, (data) => { this.replay = this.update(data) })
    },
    methods: {
        update: function (data) {
            if (data.length == 0 || !('N' in data[0])) {
              return {alt: 'Image par défaut', src: this.defaut}
            } else {
              return {alt: data[0].D, src: this.oma.radioHost + '/webpL/' + data[0].N + '.webpL'}
            }
        },
    },
    computed: {
        data: function () {
            return this.oma.player.TYPES.isReplay || this.oma.player.TYPES.isPodcast ? this.replay : this.direct
        },
    }
}
</script>

<style>
</style>
