<template>
    <button v-if="src" >Fichier {{src.D}}</button>
</template>

<script>
export default {
    props: {
        'oma': {type: Object, required: true},
        'channel': {type: Number, required: true},
    },
    data: () => ({
        srcDirect: null,
        srcReplay: null,
    }),
    created: function () {
        this.oma.ows.handler(    this.channel, (data) => { this.srcDirect = this.update(data) })
        this.oma.ows.handler('R'+this.channel, (data) => { this.srcReplay = this.update(data) })
    },
    methods: {
        update: function (data) {
            if (data.length == 0 || ! ('N' in data[0])) {
              return null
            } else {
              return data[0]
            }
        },
    },
    computed: {
        src: function () {
            return this.oma.player.TYPES.isReplay ? this.srcReplay : this.srcDirect
        },
    }
}
</script>

<style>

</style>
