<template>
    <aside v-show="visible">
        <p>On met le son&nbsp;?</p>
        <button @click="start">
            <span class="fa fa-smile-o"></span>
            Oui&nbsp;!
        </button>
        <button @click="close">
            <span class="fa volume-mute"></span>
            Non merci&nbsp;!
        </button>
      </aside>
</template>

<script>
export default {
    props: {
        oma: {type: Object, required: true},
    },

    data: () => { return {
        visible: true,
    }},
    created: function () {
        this.oma.player.onplay = () => {
            this.close()
        }
    },
    methods: {
        start: function () {
            this.oma.player.play_pause()
            this.close()
        },
        close: function () {
            this.visible = false
        },
    },
}
</script>

<style scoped>
aside {
    width:120% !important;
    position:fixed;
    bottom:0;
    padding:5px;
    left: -5%;
    text-align:center;
    background-color: #a74483;
    width: inherit;
    z-index:9999;
}

aside * {
    margin-right: 10px;
    display: inline;
}
</style>
