<template>
    <div class="oma-player-rds-container">
         {{text}}
    </div>
</template>

<script>
export default {
    props: {
        'oma': {type: Object, required: true},
        'channel': {type: Number, required: true},
    },
    data: () => ({
        textDirect: '',
        textReplay: '',
    }),
    computed: {
        text: function () {
            return this.oma.player.TYPES.isReplay || this.oma.player.TYPES.isPodcast ? this.textReplay : this.textDirect
        }
    },
    created: function () {
        var _this = this
        this.oma.ows.handler(this.channel,     (data) => {_this.textDirect = _this.update(data)})
        this.oma.ows.handler('R'+this.channel, (data) => {_this.textReplay = _this.update(data)})
    },
    methods: {
        update: function (data) {
            var text = ''
            for (var item in data) {
                text += ' '
                if ('M' in data[item]) {text += data[item]['M']}
                if ('C' in data[item]) {text += data[item]['C']}
                if ('T' in data[item]) {text += data[item]['T']}
                if ('A' in data[item]) {text += data[item]['A']}
            }
            return text
        }
    },
}
</script>

<style>

</style>
