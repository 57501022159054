var canPlayOgg = false
canPlayOgg = document.createElement('audio').canPlayType('audio/ogg') != '';

var canPlayMp3 = false
var ogv = null

if (!canPlayOgg) {
	ogv = require('ogv');
        canPlayMp3 = ogv.OGVCompat.supported('OGVPlayer')
}

function jump(h){
	/* Jump to html anchor */
	var url = location.href;               //Save down the URL without hash.
	location.href = "#"+h;                 //Go to the target element.
	history.replaceState(null,null,url);   //Don't like hashes. Changing it back.
}

function createPlayerFromOgg(src, useOgv=true) {
	/* This function creates an audio player with ogg source.
	 * If ogg is not supported, it create a ogv player
	 */
	var audio = null;
	if (canPlayOgg) {
		audio = document.createElement('audio');
		audio.src = src;
	} else if (!useOgv) {
		audio = document.createElement('audio');
		audio.src = src.slice(0,-3) + 'mp3'
	} else if (canPlayMp3) {
		audio = new ogv.OGVPlayer();
		audio.src = src;
	}
	return audio;
	
}

export function changeFavicon(src) {
	//document.head = document.head || document.getElementsByTagName('head')[0];
	var link = document.createElement('link'),
		oldLink = document.getElementById('dynamic-favicon');
	link.id = 'dynamic-favicon';
	link.rel = 'shortcut icon';
	link.href = src;
	if (oldLink) {
		document.head.removeChild(oldLink);
	}
	document.head.appendChild(link);
}


/*
 * This Class contain date conversion helpers
 */
export class OmaDateTime {
	constructor (date=null, time=null){
		/* No date given */
		if (date === null && time === null) {
			this.date = new Date()
		} else if (time !== null && typeof(date) == 'string') {
			this.fromHtmlDateTime(date,time)
		} else if (time === null && typeof(date) == 'number') {
			this.fromTimestamp(date)
		} else if (time === null && typeof(date) == 'string') {
			this.fromPacDateTime(date)
		} else if (time === null && typeof(date) == 'object') {
			this.fromJsDateTime(date)
		} else {
			throw 'Date format not reconized'
		}
	}

	fromPacDateTime (p) {
		var tab = p.split('m')
		var year = tab[0]
		tab = tab[1]+'m'+tab[2]
		tab = tab.split('j')
		var month = parseInt(tab[0]) - 1
		tab = tab[1].split('h')
		var day = tab[0]
		tab = tab[1].split('mn')
		var hours = tab[0]
		tab = tab[1].split('s')
		var min = tab[0]
		var sec = tab[1]
		this.date =  new Date(year, month, day, hours, min, sec)
		return this
	}

	fromJsDateTime (d) {
		this.date = d
		return this
	}

	/* C’est utc, on veut utc+2 */
	fromTimestamp (t){
		this.date = new Date(t*1000)
		return this
	}

	fromHtmlDateTime (d, t) {
		//dateTab = d.split('-')
		//timeTab = t.split(':')
		//this.date = new Date(dateTab[0], dateTab[1], dateTab[2], timeTab[0], timeTab[1], timeTab[2])
		this.date = new Date(d + ' ' + t)
		return this
	}

	toTimestamp(){
		return this.date / 1000
	}
	toJsDateTime(){
		return this.date
	}
	toPacDateTime(){
		return String(this.date.getFullYear())
			+ 'm' + String(this.date.getMonth()+1).padStart(2, '0')
			+ 'j' + String(this.date.getDate()).padStart(2, '0')
			+ 'h' + String(this.date.getHours()).padStart(2, '0')
			+ 'mn'+ String(this.date.getMinutes()).padStart(2, '0')
			+ 's' + String(this.date.getSeconds()).padStart(2, '0')
	}
	toHtmlDate(){
		return this.date.getFullYear() +'-'+ String(this.date.getMonth()+1).padStart(2, '0') +'-'+ String(this.date.getDate()).padStart(2, '0')
	}
	toHtmlTime(){
		return String(this.date.getHours()).padStart(2, '0') +':'+ String(this.date.getMinutes()).padStart(2, '0') +':'+ String(this.date.getSeconds()).padStart(2, '0')
	}
	toLocaleDateTime (join) {
		join = join ? join : ' '
		return this.date.toLocaleDateString() + join + this.date.toLocaleTimeString()
	}
}


/*
 * This Class connect to a omaWebServer with websockets
 * It will create a singleton, any other instance you can create with new OmaWebsocketClient() will return the same instance
 * You add a callback that will be called on a channel message with : (new OmaWebsocketClient).handler('12', function(message){console.log(message)}
 * You can add any handler you want but not remove them as this time
 * TODOs:
 * - Authorize handlers removing
 * - Set a handler to all messages (like the debug one)
 */
export class OmaWebsocketClient {
	constructor (radioHost, wssPort) {
		/* Singleton */
		const instance = this.constructor.instance;
		if (instance) {
			return instance;
		}
		this.constructor.instance = this;
		/*   Actual Constructor */
		/* string to boolean */
		this.host  = radioHost
		this.port  = wssPort

		this.channelHandlers = {}
		this.channelNumber = 105
		this.websocket = null

		this.reconnectTimeout = null

		if (this.debug) {
			this.setupDebug()
		}
		this.startWebsockets()
	}

	/* Add a handler to a channel */
	handler(channel, callback){
		/* Test channel validity */
		if (! this.isChannelValid(channel)) {
			throw 'Channel '+channel+' is not valid'
		}
		/* Initialize handler array if needed */
		if (!(channel in this.channelHandlers)) {
			this.channelHandlers[channel] = []
		}
		/* Add the handler */
		this.channelHandlers[channel].push(callback)
	}

	/* Test if the channel number is supported */
	isChannelValid (channel) {
		var number = typeof(channel) != 'number' ? channel.slice(1) : channel
		return number >= 0 && number < this.channelNumber
	}


	/* Create websocket connection */
	startWebsockets(){
		window.WebSocket = window.WebSocket || window.MozWebSocket
		this.websocket = new WebSocket(this.host+':'+this.port, 'oma-protocol');
		this.websocket.onopen = () => {
			console.log('websocket opened')
		}
		this.websocket.onerror = (e) => {
			console.error('websocket error', e)
			this.restartLater()
			this.websocket.close()
		}
		this.websocket.onmessage = (message) => {
			console.log('debug oma-lib.ws : websocket message : '+message.data)
			if (message.data == '' || message.data == 'alive'){return}
			var data = JSON.parse(message.data)

			this.gotNewWs(data)

		}

		this.websocket.onclose = () => {
			console.log('websocket closed')
			this.restartLater()
		}
	}

	gotNewWs (data) {
		/* Pass to the handlers if defined */
		for (var channel in data) {
			var channelNum = channel.slice(2) /* On récupère un chaine genre ch4 */
			if (channelNum in this.channelHandlers) {
				for (var handler in this.channelHandlers[channelNum]) {
					this.channelHandlers[channelNum][handler](data[channel])
				}
			}
		}
		/* Interractive format */
		if ('ch' in data) {
			channelNum = data['ch']
			for (handler in this.channelHandlers[channelNum]) {
				this.channelHandlers[channelNum][handler](data)
			}
		}
	}

	restartLater() {
		if (this.reconnectTimeout != null) {
			clearTimeout(this.reconnectTimeout)
		}
		this.reconnectTimeout = setTimeout(() => {
			this.startWebsockets()
			this.reconnectTimeout = null
		}, 5000)
	}

	/* This function is used to send data to the server */
	send(data){
		console.log('Debug sending: ' + data)
		this.websocket.send(data)
	}
}

export class OmaFiche {
	constructor (content) {
		this.data = {}
		this.actions = {} /* Tag actions */
		this.parse(content)
	}

	parse (content) {
		var lines = content.split('\n')
		for(var i = 0;i < lines.length;i++){
			var line = lines[i].split(':')
			var key = line[0].trim()
			var value = lines[i].slice(lines[0].length+1).trim()
			this.data[key] = value
			if (key.match(/Tag\d/)) { /* contextual tags managment */
				var beginTime = Number(key.slice(3))
				var infos = value.split(' ')
				var endTime = beginTime + Number(infos[1])
				if (! (beginTime in this.actions)) { this.actions[beginTime] = [] }
				this.actions[beginTime].push({'ch': infos[0], 'msg': infos[2]}) /* Display contextual item */
				if (! (endTime in this.actions)) { this.actions[endTime] = [] }
				this.actions[endTime].push({'ch':infos[0], 'msg': ''}) /* Hide contextual item */
			}
		}
	}

	get (key) {
		return this.data[key]
	}
}



/*
 * This Class is a sound replayer with no interface.
 * It implements the singleton pattern so there is only one player on a page.
 * Algorithm:
 * When a stream is started, the next is added and its loading is scheduled 15s before the end of this one.
 * If the user move in the stream, the loading date is computed again (how do we cancel the previus?)
 * 15s before the stream end, the next starts loading
 * On the stream end, the next starts
 * And it starts again.
 */
export class OmaPlayer{
	constructor(radioHost, omaWebsocket){
		this._radioHost = radioHost

		/* The oma websocket object */
		this._ows = omaWebsocket
		if (this._ows) {
			this._ows.handler('100', (data) => {this.got_new(data)})
		}

		/* Api contact */
		this.api = new OmaApi(this._radioHost)

		/* player states and modes. Keys and values must be indentical */
		var _this = this
		this.STATES = {
			LOADING: 'LOADING',
			PLAYING: 'PLAYING',
			PAUSED: 'PAUSED',
			IDLE: 'IDLE',
			ERROR: 'ERROR',
			WAITING_FOR_USER: 'WAITING_FOR_USER',
			get isLoading() { return _this._state == this.LOADING },
			get isPlaying() { return _this._state == this.PLAYING },
			get isPaused()  { return _this._state == this.PAUSED },
			get isIdle()    { return _this._state == this.IDLE },
			get isError()   { return _this._state == this.ERROR },
			get isWaitingForUser()   { return _this._state == this.WAITING_FOR_USER },
		}
		this.TYPES = {
			DIRECT: 'DIRECT',
			REPLAY: 'REPLAY',
			PODCAST: 'PODCAST',
			NONE: 'NONE',
			get isDirect()  { return _this._type == this.DIRECT},
			get isReplay()  { return _this._type == this.REPLAY},
			get isPodcast() { return _this._type == this.PODCAST},
			get isNone()    { return _this._type == this.NONE},
		}


		/* For txt pige indexing */
		this._textPigeBaseIndex = null
		this._ows.handler(101, (data) => {
			_this._gotNewTxtPige(_this, data)
		})
		this._ows.handler(104, (data) => {
			_this._gotPodcastTxt(_this, data)
		})
		this._ows.handler('R0', () => {
			this._txtReplayEmpty = false
		})
		this.onplay = null
		this._pigeIndexer = new OggPigeIndex(this._radioHost)

		/* setters */
		Object.defineProperty(this, "paused", {
			set : (value) => {
				if (typeof(value) != 'boolean')
					throw 'pased value must be a boolean'
				this._paused = value
			},
			get: () => {
				return this._paused
			},
		})
		Object.defineProperty(this, "volume", {
			set : (volume) => {
				if (volume < 0 || volume > 1 || isNaN(volume))
					throw 'Volume "'+volume+'" is not between 0 and 1'
				this._volume = volume
				if (this._current_stream != null && Object.prototype.hasOwnProperty.call(this._current_stream, 'player'))
					this._current_stream.player.volume = volume
				localStorage.setItem('omaVolume', volume)
			},
			get: () => {
				return this._volume
			},
		})
		Object.defineProperty(this, "muted", {
			set : (value) => {
				if (typeof(value) != 'boolean') {
					throw 'muted must be a boolean'
				}
				if (this._current_stream != null && Object.prototype.hasOwnProperty.call(this._current_stream, 'player')) {
					this._current_stream.player.muted = value
				}
				this._muted = value
				localStorage.setItem('omaMuted', value)
			},
			get: () => {
				return this._muted
			},
		})
		Object.defineProperty(this, "state", {
			set : (value) => {
				if (! (value in this.STATES)) {
					throw 'State "' + value + '" is not valid'
				}
				this._state = value
			},
			get: () => {
				return this._state
			},
		})
		Object.defineProperty(this, "type", {
			set : (value) => {
				if (! (value in this.TYPES)) {
					throw 'Type "' + value + '" is not valid'
				}
				this._type = value
			},
			get: () => {
				return this._type
			},
		})

		this.volume = parseFloat(localStorage.getItem('omaVolume')) || 1 /* Sound volume */
		this.muted = localStorage.getItem('omaMuted') == 'true'
		this.paused = false

		this.reinit()
	}

	_destroyStream(stream) {
		if (stream != null && Object.prototype.hasOwnProperty.call(stream, 'player')) {
			stream.player.pause()
			stream.player.src = ''
			stream.player.ontimeupdate = null
			stream.player.ontimeupdate = () => {
				console.error('A deleted stream is playing!')
			}
		}
		this._current_stream = {}
	}

	reinit (hard=true) {
		if (hard) {
			this._destroyStream(this._current_stream)
			this._destroyStream(this._loading_stream)
			this._ask_end = null
		}

		this._nextPigeReplayTimeout = null /* Timer returned by setInterval */
		this.currentTime = null
		this._need_to_play = null   /* The date that user asked to play */
		this._audioReplayAskedPos = null /* The pos we asked for. Useful in double replay detection */
		this.state = this.STATES.IDLE
		this.type = this.TYPES.NONE
		this.currentFile = ''
		this.errorMsg= ''
	}
	// TODO on stream error/stop?

	_start_current_stream(fromStart=false, wait=false){
		console.log('Debug _start_current_stream():') 
		if (this._current_stream == null || !Object.prototype.hasOwnProperty.call(this._current_stream, 'player')) { return false}
		var player = this._current_stream.player
		player.volume = this.volume
		player.muted = this.mute
		if (fromStart && !wait) {
			player.onplay = () => {
				this.state = this.STATES.PLAYING
				if (this.onplay) this.onplay()
			}
			player.play()
			this._check_for_next()
			return true
		}
		/* Is this a perimed stream */
		else if (this._current_stream.need_to_play != null && this._current_stream.need_to_play != this._need_to_play) {
			return false
		}
		/* Is this the stream we are waiting for? */
		else if (this._need_to_play >= this._current_stream.timestamp){
			var currentTime = this._need_to_play - this._current_stream.timestamp
			console.log('starting current stream at '+currentTime + ' seconds on '+player.duration)
			player.currentTime = currentTime
			if (!wait) {
				player.onplay = () => {
					this._need_to_play = null
					this.state = this.STATES.PLAYING
					if (this.onplay) this.onplay()
				}
				player.play()
			} else {
				this.state = this.STATES.WAITING_FOR_USER
			}
			this._check_for_next()
			return true
		}
		return false
	}

	/* The user input : play/pause */
	play_pause() {
		console.log('Debug play_pause():') 
		if (this.state == this.STATES.IDLE) {
			this.playDirect()
			return true
		}
		/* No stream exists, no date have been set */
		if(this._current_stream == null)
			return false

		var stream = this._current_stream.player
		if (this.STATES.isWaitingForUser || stream.paused) {
			stream.play()
			this.paused = false
			this.state = this.STATES.PLAYING
			this.muted = false
			if (this.onplay) this.onplay()
		} else {
			stream.pause()
			this.paused = true
			this.state = this.STATES.PAUSED
		}
		return true
	}

	playDirect(wait=false) {
		this.reinit()
		this.type = this.TYPES.DIRECT
		this.state = this.STATES.LOADING
		jump('listen')
		window.location.hash = 'direct'

		console.log('Debug playDirect')
		var stream = createPlayerFromOgg(this._radioHost+'/direct.ogg', false)
		this._current_stream = {'player': stream}
		if (this.onplay) this.onplay()



		stream.muted = this.mute
		stream.volume = this.volume
		var _this = this
		if (!wait) {
			stream.onplay = ()=>{
				/* Cleaning replay */
				this.state = this.STATES.PLAYING
			}
			stream.play()
		} else {
			this.state = this.STATES.WAITING_FOR_USER
		}
		stream.ontimeupdate = () => {
			if (!_this.paused) {
				_this.state = _this.STATES.PLAYING
			}
			_this.currentTime = Math.round(Date.now() / 1000)
		}
		stream.onended = () => { /* direct was interrupted ! */
			_this.playDirect()
		}
		stream.onabort = () => {
			//console.error('abort')
		}
		stream.onstalled = () => {
			console.error('stalled')
		}
		stream.onwaiting= () => {
			console.error('waiting')
		}
		stream.onerror = () => {
			//console.error('error')
			//_this.state = _this.STATES.ERROR
		}
	}

	playThis (simpleName, prettyName, wait=false, end=null) {
		if (parseInt(simpleName)) {
			this.set_date(simpleName, prettyName, wait, end)
		} else {
			this.playPodcast(simpleName, prettyName, wait)
		}
	}
	/******************************************************************* Podcast play *********************************************************/
	playPodcast (simpleName, prettyName, wait=false) {
		console.log('Debug playPodcast')
		jump('listen')
		this.reinit()
		this.currentFile = simpleName
		window.location.hash = simpleName
		this.type = this.TYPES.PODCAST
		if (wait)
			this.state = this.STATES.WAITING_FOR_USER
		else
			this.state = this.STATES.LOADING

		var stream = createPlayerFromOgg(this._radioHost+'/ogg/'+simpleName+'.ogg')
		this._current_stream = {'player': stream, 'prettyName': prettyName}
		if (prettyName === null) {
			this.api.getFile('/fic/'+simpleName+'.fic',
			(data) => {
				var rx = /\nTextePod : (.*)\n/g;
				var arr = rx.exec(data);
				if (arr === null) {
					console.warn('No TextePod found in fic: '+simpleName)
				} else {
					this._current_stream.prettyName = arr[1]; 
				}
			}, (error) => {
				console.log(error)
			})
		}

		stream.muted = this.mute
		stream.volume = this.volume
		var _this = this
		stream.onplay = ()=>{
			this.state = this.STATES.PLAYING
			if (this.onplay) this.onplay()
			this._ows.send('getTagLst ' + simpleName)
		}
		stream.ontimeupdate = () => {
			_this.currentTime = Math.round(_this._current_stream.player.currentTime)
		}
		stream.onended = () => {
			setTimeout(() => {
				_this.playDirect()
			}, 5000)
		}

		if (!wait) stream.play()

	}

	_gotPodcastTxt (_this, data) {
		console.log('New podcast txt :' + data.tps)
		_this._current_stream.tps = data.tps
		_this._current_stream.next_index = 0
		_this._getPodcastTime(_this)
	}

	_getPodcastTime (_this) {
		console.log('_getPodcastTime')
		/* If replay stopped for some reason */
		if (_this._type != this.TYPES.PODCAST)
			return;
		/* If we have no next tag */
		else if (_this._current_stream.tps.length < _this._current_stream.next_index+2) {
			return;
		}
		const next_time = _this._current_stream.tps[_this._current_stream.next_index]
		const now = _this.currentTime || null
		if (now === null) {
			console.log('try again in 2 sec')
			window.setTimeout(()=>{_this._getPodcastTime(_this)}, 2000)
		} else if ( now+1 > next_time  ) {
			/* If it is time to show the next podcast */
			_this._ows.send('getTag ' + _this.currentFile+ ' ' + _this._current_stream.next_index)
			_this._current_stream.next_index += 1
			_this._getPodcastTime(_this)

		} else {
			const delay = Math.max(next_time-now, 1)
			console.log('try again in ', delay)
			window.setTimeout(()=>{_this._getPodcastTime(_this)}, delay*1000)
		}
	}

	/******************************************************************* Audio replay *********************************************************/

	/* User input : set date. Date is a unix timestamp*/
	set_date(date, prettyName=null, wait=false, end=null){
		console.log('Debug set_date(): '+date) 
		if (typeof(date) != 'number' && date < 0){
			console.warn('set_date(): invalid timestamp')
			return
		}
		window.location.hash = date
		jump('listen')
		this.reinit(false)
		this.type = this.TYPES.REPLAY
		this.state = this.STATES.LOADING
		this._need_to_play = date
		this._ask_end = end
		this.currentTime = date
		this._current_stream.prettyName = prettyName; 
		if (!this._start_current_stream(false, wait)) { /* look for the time here */
			this._destroyStream(this._current_stream)
			this._current_stream = this._loading_stream
			this._loading_stream = null;
			if (this._current_stream)
				this._current_stream.prettyName = prettyName; 
			if (!this._start_current_stream(false, wait)) { /* and here */
				this._destroyStream(this._current_stream)
				this._current_stream = null;
				this._pigeIndexer.getOggFileOf(date, (timestamp, filename) => {
					if (this._need_to_play == date) { /* Check if asked replay has changed since */
						console.log('Debug set _current_stream')
						this._current_stream = this.create_replayer(timestamp, filename, date)
						this._start_current_stream(false, wait)
						this._current_stream.prettyName = prettyName; 
					}
				}, errorMsg =>{
					this._state = this.STATES.ERROR
					if (errorMsg)
						this.errorMsg = errorMsg
				})
			}
		}
	}

	set_date_human(date, time){
		if (date == '' || time == ''){
			console.warn('set_date_human(): empty date or time')
			return
		}
		console.log('Debug set_date_human():') 
		var replay_timestamp = (new OmaDateTime()).fromHtmlDateTime(date, time).toTimestamp()
		if (!replay_timestamp) {console.error('timestamp invalid');return}
		this.set_date(replay_timestamp)
	}

	_on_end(thisReplacement){
		var _this = thisReplacement ? thisReplacement : this
		this.state = this.STATES.LOADING
		console.log('Debug _on_end():') 
		if (this._current_stream === null ) { return }
		if (_this._loading_stream === null)
			throw "Next stream was not found!"
		_this._current_stream = _this._loading_stream
		_this._loading_stream = null
		_this._start_current_stream(true)
	}

	forward (amount) {
		if (this.TYPES.isReplay || this.TYPES.isDirect) {
			var seconds = this.currentTime + Number(amount)
			if (seconds === null) return;
			this.set_date(seconds)
		} else if (this.TYPES.isPodcast) {
			this._current_stream.player.currentTime += amount
		} else {
			console.error('Invalid state for forward ' + this._type)
		}
	}

	/* Create player and its environment from a pige file */
	create_replayer (file_timestamp, filename, need_to_play=null) {
		const data = {timestamp: file_timestamp, filename:filename, need_to_play: need_to_play}
		console.log('Debug create_replayer():') 

		/* Create the player */
		data.player = createPlayerFromOgg(this._radioHost+'/pige/'+filename+'.ogg')

		/* Start stream loading */
		data.player.load()
		data.player.onloadend = () => {
			//wtf?
			//this.state = this.STATES.REPLAY
		}
		data.player.onplay = () => {
			this.state = this.STATES.PLAYING
			if (this.onplay) this.onplay()
		}

		/* Be notified on streams end */
		var _this = this
		data.player.onended = () => { _this._on_end(_this) }
		data.player.ontimeupdate = () => {
			_this.currentTime = Math.floor(_this._current_stream.timestamp + _this._current_stream.player.currentTime)
			window.location.hash = _this.currentTime
		}

		return data
	}


	_check_for_next(){
		console.log('Debug _check_for_next():') 
		/* Only check for next if there is a current stream */
		if (! this._current_stream) { return }

		/* If we don’t know the stream duration, we come back in 2 secs */
		else if (isNaN(this._current_stream.player.duration))
			window.setTimeout(()=>{this._check_for_next()}, 2000)

		/* If the file is not available on the server */
		else if ( this._current_stream.need_to_play > this._current_stream.timestamp + this._current_stream.player.duration) {
			this.state = this.STATES.ERROR
			this.errorMsg = 'La date que vous avez demandé n’est pas disponible à la réécoute :('
		}
		/* If the stream is two minutes of the end, we get the next from internet */
		else if (this._current_stream.player.duration - this._current_stream.player.currentTime < 120) {
			console.log('_check_for_next: get the next!')
			this._pigeIndexer.getNextOggFileOf(this._current_stream.timestamp, (timestamp, filename) => {
				this._loading_stream = this.create_replayer(timestamp, filename)
				console.log('Debug set _loading_stream')
			}, () =>{
				// TODO un peu de blanc le temps que ça arrive ?
				//window.setTimeout(this._check_for_next(), 10000)
			})
			/* if the stream is more than a minute from the end, we come back later */
		} else {
			window.setTimeout(()=>{this._check_for_next()}, (this._current_stream.player.duration - this._current_stream.player.currentTime - 50)*1000)
		}
	}

	/********************************************** Text Pige ********************************************/

	_initTxtReplay (date) {
		console.log('Debug _initTxtReplay():') 
		this._txtReplayEmpty = true
		this._syncTxtPige(date)
	}

	/* data is the text event */
	_gotNewTxtPige (_this, data) {
		var now = _this.currentTime
		console.log('Debug _gotNewTxtPige(): at '+now+' in '+_this.state) 
		if (isNaN(now)) { setTimeout(() => {_this._gotNewTxtPige(_this, data)}, 1000) } /* come back when we got track of time */
		else if (data.status != 'Ok') { console.warn('Error getting text replay') ; _this.state = _this.STATES.ERROR } //TODO restart replay?
		else {
			this._textPigeBaseIndex = data /* save this for later indexing sync */

			if (Math.abs(now - data.N) <= 1) { /* Time to show this message is close */
				_this._ows.send('txtReplayInfo ' + (parseInt(data.pos)+1))
				setTimeout(() => { _this._askTxtPige(_this, parseInt(data.pos) + 3)}, 1000) /* get the next batch of pos in 1sec*/
			}
			else if (Math.abs(now - data.C) <= 1) { /* Time to show this message is close */
				_this._ows.send('txtReplayInfo ' + data.pos)
				setTimeout(() => { _this._gotNewTxtPige(_this, data) }, (data.N - now - 1)*1000)
			}
			else if (Math.abs(now - data.P) <= 1) { /* Time to show this message is close */
				_this._ows.send('txtReplayInfo ' + (parseInt(data.pos)-1))
				setTimeout(() => { _this._gotNewTxtPige(_this, data) }, (data.C - now - 1)*1000)
			}
			else if (data.P < now && now < data.C) {
				if (_this._txtReplayEmpty) _this._ows.send('txtReplayInfo ' + (parseInt(data.pos)-1))
				setTimeout(() => { _this._gotNewTxtPige(_this, data) }, Math.max(data.C - now - 1, .5)*1000)
			}
			else if (data.C < now && now < data.N) {
				if (_this._txtReplayEmpty) _this._ows.send('txtReplayInfo ' + data.pos)
				setTimeout(() => { _this._gotNewTxtPige(_this, data) }, Math.max(data.N - now - 1, .5)*1000)
			}
			else if (now < data.P) { /* we got prevision, not useful rigth now */
				setTimeout(() => { _this._gotNewTxtPige(_this, data) }, Math.max(data.P - now - 1, .5)*1000)
			}
			else if (now < data.P || (data.N < now && data.N != 0)) {
				_this._syncTxtPige(now)
			} else {
				console.log('txt replay is in impossible condition')
			}
		}
	}

	_askTxtPige (_this, pos) {
		_this._ows.send('txtReplayGet ' + pos)
	}

	_syncTxtPige (unixTime) {
		if (this._textPigeBaseIndex === null) {
			this._ows.send('txtReplaySync ' + unixTime)
		} else {
			var delta = this._textPigeBaseIndex.C - unixTime
			const letter = delta > 0 ? 'P' : 'N'
			delta = Math.abs(delta)
			this._ows.send('txtReplayGet' + letter + ' ' + this._textPigeBaseIndex.pos + ' ' + delta)
		}        
	}
}

export class OggPigeIndex {
	constructor (radioHost) {
		/* Singleton */
		const instance = this.constructor.instance;
		if (instance) { return instance }
		this.constructor.instance = this;
		this.api = new OmaApi(radioHost)
	}

	getOggFileOf (timestamp, callback) {
		const ts = Math.floor(timestamp/60)*60;
		callback(ts, ts)
	}

	getNextOggFileOf (timestamp, callback) {
		const ts = Math.floor(timestamp/60)*60 + 60
		callback(ts, ts)
	}
}


export class OmaApi {
	constructor (radioHost) {
		/* Singleton */
		const instance = this.constructor.instance;
		if (instance) { return instance }
		this.constructor.instance = this
		this._radioHost = radioHost
	}

	get (url, callback=null, errorCallback=null) {
		fetch(this._radioHost + url)
		.then(response => response.json())
		.then(data => {
			if (data.status != 200 && errorCallback) {
				errorCallback(data.msg)
			} else if (callback)
				callback(data.data)
		})
		.catch(error =>{
			console.error(error)
			if (errorCallback)
				errorCallback('Problème de chargement… Réessayez plus tard ou contactez nous :)')
		})
	}
	getFile (url, callback=null, errorCallback=null) {
		fetch(this._radioHost + url)
		.then(response => response.text())
		.then(data => {
			callback(data)
		})
		.catch(error =>{
			console.error(error)
			if (errorCallback)
				errorCallback('Problème de chargement… Réessayez plus tard ou contactez nous :)')
		})
	}
}
export function omaInit (config) {
    const httpHost =  (config.ssl ? 'https://' : 'http://') + config.radioHost
    const ows = new OmaWebsocketClient((config.ssl ? 'wss://' : 'ws://') + config.radioHost, config.wsPort)
    return {
        radioHost: httpHost,
        ows: ows,
        player: new OmaPlayer(httpHost, ows),
        defaultImage: '/favicon.webp',
    }
}
