<template>
    <div>
    <oma-player v-if="oma" :oma="oma" :handsetdate="true"></oma-player>
    <popup-son  v-if="oma" :oma="oma"></popup-son>
    </div>
</template>

<script>
import OmaPlayerInterface from './components/player.vue'
import PopupSon from './components/PopupSon.vue'
import {omaInit} from './frontlib.js'

export default {
  name: 'omaradio-player',
  data: () => { return {
    oma: null,
  }},
  created: function () {
    window.OMA_DATA = omaInit(window.OMA_CONFIG)
    this.oma = window.OMA_DATA
    var hash = window.location.hash.slice(1)
    const ts = parseInt(hash)
    if (hash == 'direct') {
        this.oma.player.playDirect(true)
    } else if (hash.includes('-')) {
        this.oma.player.playPodcast(hash, null, true)
    } else if (ts) {
        this.oma.player.set_date(ts, null, true)
    }
  },
  components: {
    'oma-player': OmaPlayerInterface,
    'popup-son': PopupSon,
  }
}
</script>

<style scoped>
div {
    width: 100%;
}
</style>
