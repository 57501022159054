<template>
    <div class="oma-volume-container">
       <span v-on:click="$emit('unmute')" :class="'oma-volume-button fa fa-volume-off ' + (mute?'red':'')" v-if="volume <= 0 || mute"></span>
       <span v-on:click="$emit('mute')" class="oma-volume-button fa fa-volume-down" v-else-if="volume <=50"></span>
       <span v-on:click="$emit('mute')" class="oma-volume-button fa fa-volume-up" v-else=""></span>
       <div class="oma-volume-cursor-container">
         <input class="range" type="range" v-on:input="$emit('volume', $event.target.value)" :value="volume" />
       </div>
    </div>
</template>

<script>
export default {
    props: {
        volume: { type: Number, required: true},
        mute: {type: Boolean, required: true},
    },
    data: () => { return {
    }},
}
</script>

<style>
.oma-volume-button {
    cursor: pointer;
    width: 20px;
}

.oma-volume-container {
    align-items: center;
    display: flex;
    margin-top: 10px;
    font-size: 1.3rem;
}

.oma-volume-container input {
    background-color: transparent;
}

.oma-volume-cursor-container:before {
    content: "";
    background-color: currentColor;
    width: calc(100% - 6px);
    display: block;
    height: 2px;
    position: absolute;
    align-self: center;
    margin-left: 3px;
    z-index: 0;
}

.oma-volume-cursor-container {
    line-height: 0;
    position: relative;
    display: flex;
}

input.range {
    z-index: 1;
}

.red {
    color:#ea0e0e;
}

</style>
