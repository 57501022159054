<template>
    <button v-show="data.N">Texte {{data.N}}</button>
</template>

<script>
export default {
    props: {
        'oma': {type: Object, required: true},
        'channel': {type: Number, required: true},
        'defaut': {type: String, default: ''},
    },
    data: () => ({
        dataDirect: {},
        dataReplay: {},
    }),
    created: function () {
        this.srcDirect = this.defaut
        this.srcReplay = this.defaut
        this.oma.ows.handler(    this.channel, (data) => { this.srcDirect = this.update(data) })
        this.oma.ows.handler('R'+this.channel, (data) => { this.srcReplay = this.update(data) })
    },
    methods: {
        update: function (data) {
            if (data.length == 0 || ! ('N' in data[0])) {
              return this.defaut
            } else {
              return data[0]
            }
        },
    },
    computed: {
        data: function () {
            return this.oma.player.TYPES.isReplay ? this.srcReplay : this.srcDirect
        },
    }
}
</script>

<style>

</style>
