<template>
    <div>
        <button v-on:click="$emit('forward', -120)" class="oma-forward-button oma-controls-button fa fa-undo" :disabled="!showPast" title="Reculer de 2 minutes" ><span>2'</span></button>
        <button v-on:click="$emit('forward', -20)" class="oma-forward-button oma-controls-button fa fa-undo" :disabled="!showPast" title="Reculer de 20 secondes" ><span>20</span></button>
        <button v-on:click="$emit('playpause')" class="oma-controls-button fa fa-play" v-if="paused"></button>
        <button v-on:click="$emit('playpause')" class="oma-controls-button fa fa-pause" v-else=""></button>
        <button v-on:click="$emit('forward', 20)" class="oma-forward-button oma-controls-button fa fa-repeat" :disabled="!showFuture" title="Avancer de 20 secondes" ><span>20</span></button>
        <button v-on:click="$emit('forward', 120)" class="oma-forward-button oma-controls-button fa fa-repeat" :disabled="!showFuture" title="Avancer de 2 minutes" ><span>2'</span></button>
    </div>
</template>

<script>
export default {
    props: {
        paused : {type: Boolean, required: true},
        showPast: {type: Boolean, required: false, default: false},
        showFuture: {type: Boolean, required: false, default: false},
    },
    data: () => { return {
    }},
    methods: {
    },
}
</script>

<style scoped>
.oma-controls-button {
    cursor: pointer;
    font-size: 2.5em;
    background-color: transparent;
    border: 0;
    color: inherit;
    padding: 0;
}

.oma-controls-button:disabled {
    opacity: 0;
}

.oma-forward-button span {
    position: absolute;
    top:0;
    left:0;
    font-size: 0.3em;
    margin: 40% 32%;
    font-weight: bold;
    font-family: sans-serif;
}

.oma-forward-button.fa-undo span {
    transform: rotate(25deg);
}
.oma-forward-button.fa-repeat span {
    transform: rotate(-25deg);
}
.oma-forward-button {
    position: relative;
}
.oma-forward-button.fa-undo {
    transform: rotate(-25deg);
}
.oma-forward-button.fa-repeat {
    transform: rotate(25deg)
}
</style>
