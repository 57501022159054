<template>
    <div class="d-flex">
        <div class="oma-player-board oma-radio-player" v-if="supported">
            <oma-contextual-image class="oma-player-image" :channel="8" :oma="oma" :defaut="oma.defaultImage"></oma-contextual-image>
            <div class="sided">
                <div class="oma-player-text-container">
                    <span v-show="oma.player.STATES.isLoading">
                        Chargement
                        <span v-show="oma.player.TYPES.isReplay">de la réécoute</span>
                        <span v-show="oma.player.TYPES.isPodcast">du podcast</span>
                        <span v-show="oma.player.TYPES.isDirect">du direct</span>
			(Cela peut prendre jusqu’à 1 minute sur mac ou iphone)
                    </span>
                    <span v-show="oma.player.STATES.isPlaying || oma.player.STATES.isPaused">
                        <span v-show="oma.player.TYPES.isReplay">Réécoute en cours du {{currentTime}}</span>
                        <span v-if="oma.player.TYPES.isPodcast">Vous écoutez le podcast &laquo;&nbsp;{{oma.player._current_stream.prettyName}}&nbsp;&raquo;</span>
                        <span v-show="oma.player.TYPES.isDirect">Vous écoutez le direct</span>
                    </span>
                    <span v-show="oma.player.STATES.isIdle">
                        Écoutez ou réécoutez
                    </span>
                    <span v-show="oma.player.STATES.isError">
                        {{ oma.player.errorMsg }}
                    </span>
                    <span v-show="oma.player.STATES.isWaitingForUser">
                        <span v-show="oma.player.TYPES.isDirect">Cliquez sur le bouton play pour écouter le direct</span>
                        <span v-if="oma.player.TYPES.isPodcast">Cliquez sur le bouton play pour écouter le podcast &laquo;&nbsp;{{oma.player._current_stream.prettyName}}&nbsp;&raquo;</span>
                        <span v-show="oma.player.TYPES.isReplay">Cliquez sur le bouton play pour réécouter la radio au {{currentTime}}</span>
                    </span>
                </div>
                <div>
                    <button v-show="!oma.player.TYPES.isDirect" @click="oma.player.playDirect()">Écouter le direct</button>
                </div>
                <div v-show="oma.player.STATES.isLoading">
                    <div class="fa fa-spinner rotation"></div>
                </div>
                <div v-show="!oma.player.STATES.isLoading">
                    <controls class="d-flex oma-player-controls" :show-time-controls="oma.omaWebsocket!==null" :paused="!oma.player.STATES.isPlaying" @playpause="oma.player.play_pause()" @forward="oma.player.forward($event)" :showFuture="oma.player.TYPES.isReplay || oma.player.TYPES.isPodcast" :showPast="true"></controls>
                    <volume :volume="oma.player.volume*100" :mute="oma.player.muted" @volume="oma.player.volume=($event/100.0)" @mute="oma.player.muted=true" @unmute="oma.player.muted=false"></volume>
                    <div class="handsetdate" v-if="handsetdate && replayTime">
                        <span>Réécouter la radio à cette date&nbsp;:</span><br />
                        <input v-model="replayDate" type="date" />
                        <input v-model="replayTime" type="time" step="1"/>
                        <button @click="replay">Réécouter&nbsp;!</button>
                    </div>
                </div>
                <rds v-if="oma.ows" :oma="oma" :channel="0"></rds>
            </div>
            <div>
                <oma-contextual-image class="oma-player-image" :channel="10" :oma="oma"></oma-contextual-image>
            </div>
            <div>
                <oma-contextual-podcast v-if="oma.ows" class="oma-contextual-player-podcast" :oma="oma" :channel="4"></oma-contextual-podcast>
                <oma-contextual-podcast v-if="oma.ows" class="oma-contextual-player-podcast" :oma="oma" :channel="5"></oma-contextual-podcast>
                <oma-contextual-podcast v-if="oma.ows" class="oma-contextual-player-podcast" :oma="oma" :channel="6"></oma-contextual-podcast>
                <oma-contextual-podcast v-if="oma.ows" class="oma-contextual-player-podcast" :oma="oma" :channel="7"></oma-contextual-podcast>
            </div>
            <div>
                <oma-contextual-text v-if="oma.ows" class="oma-contextual-player-text" :oma="oma" :channel="12"></oma-contextual-text>
                <oma-contextual-text v-if="oma.ows" class="oma-contextual-player-text" :oma="oma" :channel="13"></oma-contextual-text>
                <oma-contextual-text v-if="oma.ows" class="oma-contextual-player-text" :oma="oma" :channel="14"></oma-contextual-text>
                <oma-contextual-text v-if="oma.ows" class="oma-contextual-player-text" :oma="oma" :channel="15"></oma-contextual-text>
            </div>
            <div>
                <oma-contextual-file v-if="oma.ows" class="oma-contextual-player-file" :oma="oma" :channel="12"></oma-contextual-file>
                <oma-contextual-file v-if="oma.ows" class="oma-contextual-player-file" :oma="oma" :channel="13"></oma-contextual-file>
                <oma-contextual-file v-if="oma.ows" class="oma-contextual-player-file" :oma="oma" :channel="14"></oma-contextual-file>
                <oma-contextual-file v-if="oma.ows" class="oma-contextual-player-file" :oma="oma" :channel="15"></oma-contextual-file>
            </div>
        </div>
        <div v-else="">
            Votre navigateur ne supporte pas les fichiers mp3 et ogg…
        </div>
    </div>
</template>

<script>
//const caniuse = require('caniuse-api')
import Controls from './controls.vue'
import Volume from './volume.vue'
import Rds from './rds.vue'
import Image from './image.vue'
import {OmaDateTime} from 'oma-front-lib/src'
import ContextualText from './ContextualText'
import ContextualFile from './ContextualFile'
import ContextualPodcast from './ContextualPodcast'

export default {
    props: {
        oma: {type: Object, required: true},
        handsetdate: {type: Boolean, default: true},
    },
    data: () => { return {
        supported: true, //TODO
        playText: 'Écoutez ou réécoutez',
        loading: false,
        mute: false,
        volume: 100,
        replayDate: null,
        replayTime: null,
    }},
    computed: {
        currentTime: function () {
            var d = new OmaDateTime(this.oma.player.currentTime)
            return d.toHtmlDate() + ' ' + d.toHtmlTime()
        }
    },
    created: function () {
        var d = new Date()
        d.setHours(d.getHours()-1)
        d = (new OmaDateTime()).fromJsDateTime(d)
        this.replayDate = d.toHtmlDate()
        this.replayTime = d.toHtmlTime()

    },
    methods: {
        replay: function () {
            this.oma.player.set_date_human(this.replayDate, this.replayTime)
        }
    },
    components: {
        controls: Controls,
        volume: Volume,
        rds: Rds,
        'oma-contextual-image': Image,
        'oma-contextual-text': ContextualText,
        'oma-contextual-file': ContextualFile,
        'oma-contextual-podcast': ContextualPodcast,
    },
}
</script>

<style scoped>
.oma-player-controls {
    justify-content:space-around;
}
.d-flex {
    display:flex;
}
.oma-radio-player {
    width: 600px;
}
.oma-player-image {
    height: 200px;
}
.oma-player-text-container {
    margin-bottom: 1em;
}
.handsetdate input {
   background-color: transparent;
   border: 1px solid;
   margin: 0 5px;
}

.rotation {
    animation: rotation 1s;
    animation-timing-function: steps(8);
    animation-iteration-count: infinite;
	font-size:3em;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

</style>
